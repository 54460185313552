import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="about-d1">
      {/* <img
        className="nav-about-img"
        src={require("../../assets/Img/img6.jpeg")}
        alt=""
      /> */}

      <div className="about-d2">
        <h1>About Manpreet</h1>
      </div>

      <div className="about-d3">
        <div className="about-d4">
          <div className="lh-about">
            <h1> MY JOURNEY</h1>
            <p>
              My journey I AM THE WHEELCHAIR BODYBUILDER .. LIFESTYLE &
              TRANSFORMATION COACH I HAVE BEEN TRAINING AND DIETING FOR THE PAST
              11 YEARS AND STARTED COMPETING IN 2019 AND HAVE WON SHERE CLASSIC
              2 TIME AND AMATEUR OLYMPIA SILVER MEDALIST AS AN PHYSICAL
              CHALLENGES <br /> <br />   As a physical challenge person I KNOW HOW HARD IT IS
              TO BALANCE HEALTHY EATING AND FITNESS WITH A DEMANDING PROFESSION.
              THROUGH MY ASCENSION PROGRAMS, MY GOAL IS TO HELP IMPROVE ONEÍS
              PHYSIQUE THROUGH OPTIMAL TRAINING AND NUTRITION. IT IS ABOUT
              GETTING BETTER; PHYSICALLY, EMOTIONALLY AND MENTALLY. FITNESS IS
              NOT ALL ABOUT THOSE BEEFED UP MUSCLES.
              <br /> <br />  
              
              IT IS A WAY OF LIFE THAT
              NEEDS DEDICATION AND PERSEVERANCE. MANPREET NIJJAR, HAILING FROM
              Chandigarh , REDEFINED THE FITNESS INDUSTRY IN INDIA WHEN HE
              STARTED OFF 11 YEARS AGO IN 2013. HIS CALLING WAS TO HELP OTHERS
              TO REALIZE THEIR GREATNESS, THEIR POTENTIAL, THEIR GIFTS. A STRONG
              VISION TO CHANGE THE LIVES OF PEOPLE BY NOT JUST GROWING THEIR
              PHYSIQUES BUT ALSO BRAINS AND SPIRIT.
            </p>
          </div>
          <div className="rh-about">
            <img src={require("../../assets/Img/about1.jpg")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
