import React, { useRef } from "react";
import videoFile from "../../assets/Video/video1.mov";
import { FaLongArrowAltRight } from "react-icons/fa";
import ReactPlayer from "react-player";
import ReactWhatsapp from "react-whatsapp";

const Home4 = () => {
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const message = `Name-
  Gender-
  Age-
  Current weight-
  Goal -
  Veg/non veg -
  Medical condition-
  Any injury-
  DAILY ROUTINE 
  1 wakeup time -
  2 Gym time -
  3 Cardio time -
  4 work time -
  5 Bed time -
  6 Any anabolic history/ current use any anabolic-
  7 Instagram id -
  8 location-
  
  Current pictures - current pictures must be from STRAIGHT,SIDE AND BACK FULLY RELAXED
  
  Anything additional you want to tell me apart from above please mention it below-
  
  If you want to call me  Before enrolment .than book your slot/ appointment 
  Enrolment call 📞 charges 100 rupees  . In this call i will fully explain how we work and you also clear all your doubts.
  9 You want to need a Enrolment call     yes/No —`;


  return (
    <div>
      {/* <ReactPlayer
        muted
        autoPlay
        controls
        url={require("../../assets/Video/video1.mov")}
      /> */}

      <div className="hc-4-d1">
        <video
          className="video-hc4"
          ref={videoRef}
          autoPlay
          muted
          playsInline
          loop
        >
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hc-4-d3">
          <h1>TRAIN WITH ME</h1>


          <ReactWhatsapp number={"+91 7743-043553"} message={message}>
          Begin Now <FaLongArrowAltRight />
        </ReactWhatsapp>


        </div>
      </div>
    </div>
  );
};

export default Home4;
