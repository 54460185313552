import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
const Home6 = () => {
  const images = [
    require("../../assets/Transformations/t11.jpeg"),
    require("../../assets/Transformations/t12.jpeg"),
    require("../../assets/Transformations/t1.jpeg"),
    require("../../assets/Transformations/t2.jpeg"),
    require("../../assets/Transformations/t3.jpeg"),
    require("../../assets/Transformations/t4.jpeg"),
    require("../../assets/Transformations/t5.jpeg"),
    require("../../assets/Transformations/t6.jpeg"),
    require("../../assets/Transformations/t7.jpeg"),
    require("../../assets/Transformations/t8.jpeg"),
    require("../../assets/Transformations/t9.jpeg"),
    require("../../assets/Transformations/t10.jpeg"),





    // Add more image URLs as needed
  ];

  const [toggler, setToggler] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setToggler(!toggler);
  };

  const data = [{ id: 1, image: "" }];

  return (
    <>
      <div className="hc-6-d1">
        <h1 className="hc6-h1">TRANSFORMATION RESULTS</h1>
        <div className="hc-6-d2">
          <div className="hc-6-d3">
            <div className="hc-6-d4">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index}`}
                  onClick={() => openLightbox(index)}
                  className="trans-images"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={images}
        sourceIndex={selectedImageIndex !== null ? selectedImageIndex : 0}
      />
    </>
  );
};

export default Home6;
