import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";

import { Toaster } from "react-hot-toast";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Program1 from "./Pages/Programs/Program1";
import Program2 from "./Pages/Programs/Program2";
import Program3 from "./Pages/Programs/Program3";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import EBook from "./Pages/EBook/EBook";
import Term from "./Pages/Policy/Term";
import Refund from "./Pages/Policy/Refund";
import ScrollToTopButton from "./Components/ScrollTo";
import ScrollToTop from "./Components/ScrollToTop";

const App = () => {
  return (
    <Router>
      <ScrollToTopButton/>
      <Toaster />
    <ScrollToTop/>

      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/EBook" element={<EBook />} />
        <Route exact path="/Terms-conditions" element={<Term />} />
        <Route exact path="/Refund-return-policy" element={<Refund />} />

        <Route exact path="/Health" element={<Program1 />} />
        <Route exact path="/Muscel" element={<Program2 />} />
        <Route exact path="/Competition" element={<Program3 />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
