import React from "react";
import CountUp from "react-countup";
const Home7 = () => {
  return (
    <div className="hc7-d1">
      <h1 className="hc7-h1">MANPREET'S ACHIEVEMENTS</h1>

      <div className="hc7-d2">
        <div className="hc7-d3">
          <div className="hc7-d4">
            <div className="hc7-d5">
              <CountUp end={11} className="count-no" />
              <h1>YEARS OF EXPERIENCE</h1>
            </div>
            <div className="hc7-d5">
              <CountUp end={2} className="count-no" />
              <h1>GOLD MEDAL IN SHERU CLASSIC</h1>
            </div>
            <div className="hc7-d5">
              <CountUp end={1} className="count-no" />
              <h1>AMATURE OLYMPIA MEDAL</h1>
            </div>
            <div className="hc7-d5">
                <div className="plus-count">

            <CountUp  end={100}  className="count-no" />
            <h1>+</h1>
                </div>
       
              <h1>TRANSFORMATION STORIES</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home7;
