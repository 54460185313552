import React from "react";
import "./Programs.css";
import Working from "../../Components/Working/Working";
import Plan from "../../Components/Plan/Plan";
import ReactWhatsapp from "react-whatsapp";
const Program2 = () => {
  const data = [
    {
      id: 1,
      title: "4 week muscle build / fat loss program",
      price: "6000",
      image: require("../../assets/Img/img3.jpeg"),
      point1: "SUPER LONG QUESTIONNAIRE TO GET MAXIMUM INSIGHT",
      point2: "BLOOD WORK ANALYSIS (IF REQUIRED",
      point3: " maximum results for your time and energy",
      point4:
        "You're ready to make positive long term changes to your health and lifestyle",
      point5:
        "TO TEACH YOU EVERYTHING YOU NEED TO KNOW ABOUT FITNESS, HEALTH AND BODY RE-COMP",
      point6:
        "COMPLETE NUTRITION: BASED ON YOUR PREFERENCES, GOALS AND EATING HABITS",
      point7:
        "TRAINING SCHEDULE: CRAFTED ACCORDANCE WITH YOUR PRESENT LEVEL, TRAINING HISTORY, HEALTH, EQUIPMENT AVAILABILITY",
      point8:
        "You're open to learning and trying new things i teach you how to naturally boost your testosterone levels and improve your sexual life",
     
    },
    {
      id: 2,
      title: "8 week muscle build / fat loss program",
      price: "10000",
      image: require("../../assets/Img/img2.jpeg"),

      point1: "SUPER LONG QUESTIONNAIRE TO GET MAXIMUM INSIGHT",
      point2: "BLOOD WORK ANALYSIS (IF REQUIRED",
      point3: " maximum results for your time and energy",
      point4:
        "You're ready to make positive long term changes to your health and lifestyle",
      point5:
        "TO TEACH YOU EVERYTHING YOU NEED TO KNOW ABOUT FITNESS, HEALTH AND BODY RE-COMP",
      point6:
        "COMPLETE NUTRITION: BASED ON YOUR PREFERENCES, GOALS AND EATING HABITS",
      point7:
        "TRAINING SCHEDULE: CRAFTED ACCORDANCE WITH YOUR PRESENT LEVEL, TRAINING HISTORY, HEALTH, EQUIPMENT AVAILABILITY",
      point8:
        "You're open to learning and trying new things i teach you how to naturally boost your testosterone levels and improve your sexual life",
     
    },
    {
      id: 3,
      title: "12 week muscle build / fat loss program",
      price: "15000",
      image: require("../../assets/Img/img6.jpeg"),

      point1: "SUPER LONG QUESTIONNAIRE TO GET MAXIMUM INSIGHT",
      point2: "BLOOD WORK ANALYSIS (IF REQUIRED",
      point3: " maximum results for your time and energy",
      point4:
        "You're ready to make positive long term changes to your health and lifestyle",
      point5:
        "TO TEACH YOU EVERYTHING YOU NEED TO KNOW ABOUT FITNESS, HEALTH AND BODY RE-COMP",
      point6:
        "COMPLETE NUTRITION: BASED ON YOUR PREFERENCES, GOALS AND EATING HABITS",
      point7:
        "TRAINING SCHEDULE: CRAFTED ACCORDANCE WITH YOUR PRESENT LEVEL, TRAINING HISTORY, HEALTH, EQUIPMENT AVAILABILITY",
      point8:
        "You're open to learning and trying new things i teach you how to naturally boost your testosterone levels and improve your sexual life",
     

    },
  ];

  return (
    <div>
      <img
        className="img-health1"
        src="https://images.unsplash.com/photo-1519505907962-0a6cb0167c73?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQzfHxib2R5YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D"
        alt=""
      />

      <div className="health-d1">
        <div className="health-d2">
          <div className="health-lh">
            <h1>
              MANPREET'S PERSONALIZED <br /> muscle build / fat loss program
            </h1>
            <p>
              muscle build / fat loss program FOR THOSE WAITING TO ACHIEVE THEIR
              PERSONAL FITNESS & PHYSIQUE GOALS . THE LIFESTYLE COACHING PROGRAM
              HAS BEEN CREATED SPECIFICALLY FOR THOSE INDIVIDUALS WHO WANT TO BE
              FIT, FEEL HEALTHY AND STAY LEAN ALL YEAR ROUND. WHETHER YOUíRE
              SOMEONE WHO WANTS TO GET BEACH-READY OR JUST LOSE SOME BODY FAT
              AND BUILD MUSCLE, THIS PROGRAM IS FOR YOU
            </p>
            <ReactWhatsapp
              number={"+91 7743-043553"}
              message={
                "Hello, I'm interested in joining a muscle build / fat loos program coaching program."
              }
           
            >
              ENROLL NOW
            </ReactWhatsapp>
          </div>

          <div className="health-rh">
            <img src={require("../../assets/Img/img2.jpeg")} alt="" />
          </div>
        </div>
      </div>

      <Working />
      <Plan data={data} />
    </div>
  );
};

export default Program2;
