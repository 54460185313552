import React from "react";
import { Link } from "react-router-dom";

const Term = () => {
  return (
    <div className="policy-d1">
      <div className="policy-d2">
        <div className="policy-d3">
          <h1>TERMS & CONDITIONS</h1>
          <h2>
            <Link to={"/"}>HOME</Link> / POLICY
          </h2>
        </div>
      </div>
      <div className="policy-d4">
        <div className="policy-d5">
          <div className="policy-d6">
            <h1>TERMS & CONDITIONS</h1>
            <h2>TERMS OF USE</h2>
            <p>
              MEAL/NUTRITION PLANS, WORKOUT PLANS DIGITAL PRODUCTS, DIGITAL
              PROPERTY AND INTELLECTUAL PROPERTY 1) MAY NOT BE POSTED 2) EMAILED
              3) COPIED OR DISTRIBUTED IN ANY SORT OF FASHION. THAT YOU HEREBY
              AGREE THAT ANY SUCH ACTION IS AN INTERNATIONAL COPYRIGHT
              INFRINGEMENT AND SUCH PENALTY WILL RESULT IN GUARANTEED
              PROSECUTION OF UP TO ₹1,00,00,000.00.
              <br /> <br />
              PLEASE REVIEW OUR INFORMATION, LEGAL TERMS, AND PRIVACY POLICY
              BEFORE USING THE SITE. <br /> <br /> TERMS AND CONDITIONS OF USE
              PLEASE CAREFULLY READ THESE TERMS AND CONDITIONS, INCLUDING OUR
              PRIVACY POLICY, BEFORE USING THIS WEBSITE
              “HTTPS://MANPREETNIJJAR.COM/”. BY ACCESSING OR USING THE SITE, YOU
              (“USER”) AGREE TO BE LEGALLY BOUND BY THESE TERMS AND CONDITIONS.
              IF YOU DISAGREE WITH ANY OF THE FOLLOWING TERMS OR CONDITIONS,
              PLEASE DO NOT USE THIS SITE.
            </p>
            <h2>HEALTH DISCLAIMERS</h2>
            <p>
              THIS WEBSITE IS AN EDUCATIONAL SERVICE THAT PROVIDES GENERAL
              HEALTH INFORMATION. THE MATERIALS IN HTTPS://MANPREETNIJJAR.COM/
              ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER
              EXPRESS OR IMPLIED. <br /> <br /> AS AN EXPRESS CONDITION TO USING
              THIS WEBSITE, YOU MUST AGREE TO THE FOLLOWING TERMS. IF YOU
              DISAGREE WITH ANY OF THESE TERMS, DO NOT USE OUR WEBSITE. YOUR USE
              OF THIS WEBSITE, AND ANY PARTICIPATION IN ACTIVITIES MENTIONED ON
              THIS WEBSITE, MEAN THAT YOU ARE AGREEING TO BE LEGALLY BOUND BY
              THESE TERMS. <br /> <br /> 1A. THE WEBSITE’S CONTENT IS NOT A
              SUBSTITUTE FOR DIRECT, PERSONAL, PROFESSIONAL MEDICAL CARE AND
              DIAGNOSIS. NONE OF THE DIET PLANS OR EXERCISES (INCLUDING PRODUCTS
              AND SERVICES) MENTIONED AT HTTPS://MANPREETNIJJAR.COM/ <br />{" "}
              <br /> 1B. THE WEBSITE’S CONTENT IS NOT A SUBSTITUTE FOR DIRECT,
              PERSONAL, PROFESSIONAL MEDICAL CARE AND DIAGNOSIS. WE ARE NOT
              MEDICAL PROFESSIONALS AND ANYTHING ON THIS WEBSITE SHOULD BE
              CLEARED WITH YOUR PHYSICIAN PRIOR TO USE. <br /> <br /> 2. THERE MAY BE RISKS
              ASSOCIATED WITH PARTICIPATING IN ACTIVITIES MENTIONED ON
              HTTPS://MANPREETNIJJAR.COM/ FOR PEOPLE WITH BOTH KNOWN AND UNKNOWN
              CONDITIONS, OR IN POOR HEALTH. BECAUSE THESE RISKS EXIST, YOU WILL
              NOT PARTICIPATE IN SUCH DIET PLANS IF YOU ARE IN POOR HEALTH OR
              HAVE A PRE-EXISTING MENTAL OR PHYSICAL CONDITION. IF YOU CHOOSE TO
              PARTICIPATE IN THESE RISKS, YOU DO SO OF YOUR OWN FREE WILL AND
              ACCORD, KNOWINGLY AND VOLUNTARILY ASSUMING ALL RISKS ASSOCIATED
              WITH SUCH DIETARY ACTIVITIES. <br /> <br /> 3. FACTS AND INFORMATION ARE
              BELIEVED TO BE ACCURATE AT THE TIME THEY WERE PLACED IN
              HTTPS://MANPREETNIJJAR.COM/. ALL DATA PROVIDED ON THIS WEBSITE IS
              TO BE USED FOR INFORMATION PURPOSES ONLY. THE PRODUCTS AND
              SERVICES DESCRIBED ARE ONLY OFFERED IN JURISDICTIONS WHERE THEY
              MAY BE LEGALLY OFFERED. INFORMATION PROVIDED IS NOT ALL-INCLUSIVE
              AND IS LIMITED TO INFORMATION THAT IS MADE AVAILABLE AND SUCH
              INFORMATION SHOULD NOT BE RELIED UPON AS ALL-INCLUSIVE OR
              ACCURATE . <br /> <br /> 4. YOU AGREE TO HOLD HTTPS://MANPREETNIJJAR.COM/, ITS
              OWNERS, AGENTS, AND EMPLOYEES AT NO FAULT FROM ANY AND ALL
              LIABILITY FOR ALL CLAIMS FOR DAMAGES DUE TO INJURIES, INCLUDING
              ATTORNEY FEES AND COSTS, INCURRED BY YOU OR CAUSED TO THIRD
              PARTIES BY YOU, ARISING OUT OF THE FITNESS AND DIET PLANS
              DISCUSSED ON THIS WEBSITE. <br /> <br /> 5. TESTIMONIALS, CASE STUDIES, AND
              EXAMPLES FOUND AT HTTPS://MANPREETNIJJAR.COM/ ARE UNVERIFIED
              RESULTS THAT HAVE BEEN FORWARDED TO US BY USERS OF
              HTTPS://MANPREETNIJJAR.COM/, AND MAY NOT REFLECT THE TYPICAL
              PURCHASER’S EXPERIENCE, MAY NOT APPLY TO THE AVERAGE PERSON AND
              ARE NOT INTENDED TO REPRESENT OR GUARANTEE THAT ANYONE WILL
              ACHIEVE THE SAME OR SIMILAR RESULTS. IF WE HAVE DISCLOSED TYPICAL
              RESULTS BASED ON INFORMATION PROVIDED TO US BY A MANUFACTURER OR
              OTHER REPUTABLE THIRD-PARTY SOURCE, YOU SHOULD PRESUME THAT THE
              TYPICAL RESULTS AS STATED ARE MORE RELIABLE THAN THE TESTIMONIALS
              AND OTHER EXAMPLES FOUND AT HTTPS://MANPREETNIJJAR.COM/. HOWEVER,
              YOU SHOULD ALWAYS PERFORM DUE DILIGENCE AND NOT TAKE SUCH RESULTS
              AT FACE VALUE. WE ARE NOT RESPONSIBLE FOR ANY ERRORS OR OMISSIONS
              IN TYPICAL RESULTS INFORMATION SUPPLIED TO US BY MANUFACTURERS OR
              OTHER REPUTABLE THIRD PARTIES. IF A PRODUCT OR SERVICE IS NEW, YOU
              UNDERSTAND THAT IT MAY NOT HAVE BEEN AVAILABLE FOR PURCHASE LONG
              ENOUGH TO PROVIDE AN ACCURATE RESULTS HISTORY.<br /> <br /> 6. ALL INFORMATION
              OBTAINED FROM THIS SITE IS LICENSED THROUGH MANPREET NIJJAR
              FITNESS, INC. AND IS NOT TO BE SHARED WITH ANY SOCIAL MEDIA
              OUTLETS, PUBLICATIONS, OR OTHER ONLINE MEDIA. YOU WILL BE SUBJECT
              TO LEGAL PROSECUTION IF YOU DO SO. <br /> <br /> PLEASE USE COMMON SENSE WHEN
              DOING ANYTHING MENTIONED ON THIS WEBSITE, EXERCISE BY NATURE IS
              DANGEROUS. IF YOU GET HURT WE WON’T BE HELD RESPONSIBLE, BUT WE
              WILL SURELY BE SAD, WE NEVER EVER WANT TO SEE OR HEAR OF ANYONE
              GETTING HURT!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Term;
