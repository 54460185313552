import React from "react";
import "./Home.css";
import ReactWhatsapp from "react-whatsapp";

const Home1 = () => {
  const message = `Name-
  Gender-
  Age-
  Current weight-
  Goal -
  Veg/non veg -
  Medical condition-
  Any injury-
  DAILY ROUTINE 
  1 wakeup time -
  2 Gym time -
  3 Cardio time -
  4 work time -
  5 Bed time -
  6 Any anabolic history/ current use any anabolic-
  7 Instagram id -
  8 location-
  
  Current pictures - current pictures must be from STRAIGHT,SIDE AND BACK FULLY RELAXED
  
  Anything additional you want to tell me apart from above please mention it below-
  
  If you want to call me  Before enrolment .than book your slot/ appointment 
  Enrolment call 📞 charges 100 rupees  . In this call i will fully explain how we work and you also clear all your doubts.
  9 You want to need a Enrolment call     yes/No —`;

  return (
    <div className="ch1-bg-1">
      <div className="hc-2-d1">
        <h1>LET'S ASCEND YOUR PHYSIQUE</h1>

        <ReactWhatsapp number={"+91 7743-043553"} message={message}>
          Begin The Transformation
        </ReactWhatsapp>
      </div>
    </div>
  );
};

export default Home1;
