import React from "react";
import "./Working.css";
const Working = () => {
  const data = [
    {
      id: 1,
      title: "FITNESS ASSESSMENT",
      Image: require("../../assets/Img/W1.png"),
      Description:
        "ONCE THE PAYMENT IS PROCESSED YOU WILL RECEIVE AN EMAIL WITH A QUESTIONNAIRE FROM ME. ANSWER HONESTLY AND PROVIDE AS MUCH DETAIL AS YOU CAN. YOUR ANSWERS WILL HELP ME BUILD THE BEST CUSTOM PLAN FOR YOUR GOALS AND YOUR BODYS NEEDS.",
    },
    {
      id: 2,
      title: "GETTING STARTED",
      Image: require("../../assets/Img/w2.png"),
      Description:
        "ONCE YOU RECEIVE YOUR PROGRAM, IT IS EXPECTED THAT YOU WILL BEGIN WITHIN 48 HOURS. IF YOU HAVE ANY QUESTIONS OR NEED ASSISTANCE GETTING STARTED, SUPPORT IS ALWAYS AVAILABLE VIA EMAIL. YOU WILL ALSO GET MY PHONE NUMBER TO HAVE 24*7 COMMUNICATION WITH ME.",
    },
    {
      id: 3,
      title: "CUSTOM PROGRAM",
      Image: require("../../assets/Img/w3.png"),
      Description:
        "THE INFORMATION FROM THE QUESTIONNAIRE WILL BE USED TO GATHER ALL INFORMATION NEEDED TO CREATE YOUR CUSTOM PROGRAM INCLUDING PHYSIQUE STATS, HEALTH HISTORY, CURRENT HEALTH STATUS, INJURIES YOU MAY HAVE (IF ANY), YOUR CURRENT EATING/EXERCISE HABITS, ANY FOOD ALLERGIES AND YOUR GOALS.",
    },
    {
      id: 4,
      title: "WEEKLY CHECK-INS",
      Image: require("../../assets/Img/w4.png"),
      Description:
        "CHECK-INS ARE TO BE MADE WEEKLY ON THE DAY STATED IN YOUR PROGRAM.",
    },
    {
      id: 5,
      title: "PHOTO ASSESSMENT",
      Image: require("../../assets/Img/w5.png"),
      Description:
        "YOU WILL ALSO BE REQUIRED TO SUBMIT CURRENT PICTURES OF YOURSELF. ONCE THE QUESTIONNAIRE IS COMPLETED AND RETURNED, YOUR PROGRAM WILL THEN BE PREPARED AND DELIVERED TO YOU VIA EMAIL WITHIN 6 BUSINESS DAYS. (USUALLY SOONER).",
    },
    {
      id: 6,
      title: "ADJUSTMENTS",
      Image: require("../../assets/Img/w6.png"),
      Description:
        "YOUR CUSTOM PROGRAM WILL BE CHANGED/ADJUSTED AT LEAST BI-WEEKLY DEPENDING ON YOUR PROGRESS AND GOALS.",
    },
  ];

  return (
    <div className="work-d1">
      <h1 className="work-heading">How It Works</h1>

      <div className="work-d2">
        <div className="work-d3">
          <div className="work-d4">
            {data.map((item) => (
              <>
                <div className="work-d5">
                  <img src={item?.Image} alt="" />
                  <h1>{item.title}</h1>
                  <p>{item.Description}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Working;
