import React from "react";
import { useNavigate } from "react-router-dom";

const Home3 = () => {
  const navigation = useNavigate();

  const data = [
    {
      id: 1,
      image: require("../../assets/Background/bg1.jpeg"),
      title: "E-BOOK ",
      link: "/EBook",

      description:
        "We often work one-on-one with individuals to assess their needs and develop a lifestyle strategy that can help them meet fitness goals, like weight loss or muscle gain ",
    },
    {
      id: 2,
      image: require("../../assets/Img/img6.jpeg"),
      title: "Health Support Programs ",
      link: "/Health",

      description:
        "improve LIFESTYLE COACHING PROGRAM Focus on improving mental health, sleep/stress ",
    },
    {
      id: 3,
      image: require("../../assets/Img/img2.jpeg"),
      title: "muscle build / fat loss program",
      link: "/Muscel",

      description:
        "A LIFESTYLE PROGRAM FOR THOSE WAITING TO ACHIEVE THEIR PERSONAL FITNESS & PHYSIQUE GOALS.",
    },
    {
      id: 4,
      image: require("../../assets/Img/img3.jpeg"),
      title: "COMPETITION COACHING PROGRAM",

      link: "/Competition",

      description:
        "FOR THOSE LOOKING TO GET STAGE READY, WHETHER ITíS YOUR FIRST COMPETITION OR COMPETING AT THE PRO LEVEL.",
    },
  ];

  return (
    <div className="hc-d1">
      <h1 className="hc2-heading">TRANSFORMATION PROGRAMS</h1>
      <div className="hc-d2">
        <div className="hc-d3">
          {data.map((item) => (
            <div className="cards-hc3">
              <img src={item?.image} alt="" />
              <div className="card-data-hc3">
                <h1>{item?.title}</h1>
                <p>{item?.description}</p>
                <button onClick={() => navigation(item.link)}>
                  Begin Here
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home3;
