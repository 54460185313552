import React from "react";
import "./Contact.css";
import Contact1 from "../../Components/Contact/Contact1";
const Contact = () => {
  return (
    <div>
      <div className="contact-d2">
        <h1>CONTACT ME</h1>
      </div>

      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54872.10243182113!2d76.72951406217962!3d30.732273943392315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0be66ec96b%3A0xa5ff67f9527319fe!2sChandigarh!5e0!3m2!1sen!2sin!4v1703520941901!5m2!1sen!2sin"
          // width="100%"
          // height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
<Contact1/>
    </div>
  );
};

export default Contact;
