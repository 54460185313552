import React, { Fragment } from "react";
import Home1 from "../../Components/HomeComponents/Home1";
import Home3 from "../../Components/HomeComponents/Home3";
import Home4 from "../../Components/HomeComponents/Home4";
import Home5 from "../../Components/HomeComponents/Home5";
import Home6 from "../../Components/HomeComponents/Home6";
import Home7 from "../../Components/HomeComponents/Home7";
import Contact1 from "../../Components/Contact/Contact1";

const Home = () => {
  return (
    <Fragment>
      <Home1 />
      <Home3 />
      <Home4 />
      <Home5 />
      <Home6 />
      <Home7 />
      <Contact1/>



    </Fragment>
  );
};

export default Home;
