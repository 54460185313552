import React from 'react'
import '../../Pages/Contact/Contact.css'
const Contact1 = () => {
  return (

    <div className="contact-d3">
    <h1>MAIL AT : man.nijjarboy@yahoo.in</h1>
    <h2>Contact No : +91 7743-043553</h2>
  </div>
  )
}

export default Contact1